import React from 'react'
import PropTypes from 'prop-types'
import {Container, Row, Col} from '../../../components/ui/wrapper'
import Heading from '../../../components/ui/heading'
import Text from '../../../components/ui/text'
import HubspotForm from '../../../components/forms/hubspot-form'
import {ContactFormWrapper, LeftBox} from './contact-form-area.style'
import { useStaticQuery } from 'gatsby'

const ContactFormArea = ({headingStyle, textStyle}) => {

    const data = useStaticQuery(graphql`
    {
      globalYaml(name: {eq: "contact-us"}) {
        left_pane {
          line_one
          line_three
          line_two
          subtext
        }
      }
    }
  `)

  const { line_one, line_two, line_three, subtext} = data.globalYaml.left_pane
    return (
        <ContactFormWrapper>
            <Container>
                <Row alignitems="center">
                    <Col lg={6}>
                        <LeftBox>
                            <Heading {...headingStyle}>{line_one}<br/> <span>{line_two}</span><br/> {line_three} </Heading>
                            <Text {...textStyle}>{subtext}</Text>
                        </LeftBox>
                    </Col>
                    <Col lg={6}>
                        <HubspotForm/>
                    </Col>
                </Row>
            </Container>
        </ContactFormWrapper>
    )
}

ContactFormArea.propTypes = {
    headingStyle: PropTypes.object,
    textStyle: PropTypes.object
}

ContactFormArea.defaultProps = {
    headingStyle: {
        as: "h3",
        position: "relative",
        pl: '34px',
        lineheight: 1.67,
        fontweight: 600,
        child: {
            color: 'primary'
        },
        layout: 'quote'
    },
    textStyle: {
        mt: '15px',
        fontSize: '18px',
        ml: '34px',
        color: '#696969'
    }
}

export default ContactFormArea;